import React, { Component } from "react";
import Whatsapp from './Whatsapp';
import Facebook from './Facebook';
import PhoneCall from './PhoneCall';
import ComplainLink from './ComplainLink';

class Agent extends Component {
  render() {
    return (
      <>
        <tr id={this.props.keyid} className={this.props.rowcls}>
          <th align="center">{this.props.type}</th>
          <th align="center">{this.props.name}</th>
          <th align="center">{this.props.id}</th>
          <th align="center">{this.props.app}</th>
          <th align="center">{this.props.phone}</th>
          <th align="center">{this.props.uplink}</th>
        </tr>
      </>
    );
  }
}

class AgentBody extends Component {
  render() {
    return (
      <>
        <tr id={this.props.keyid} className={this.props.rowcls}>
          <td align="center">{this.props.type}</td>
          <td align="center">{this.props.name}</td>
          <td align="center">{this.props.id}</td>
          <td align="center">
            {/* <Whatsapp phone={this.props.phone} width="25"/> */}
            {(this.props.phone)?<Whatsapp phone={this.props.phone} width="25"/>:''}
            {(this.props.fb)?<Facebook fb={this.props.fb} width="25"/>:''}
          </td>
          <td align="center"><PhoneCall phone={this.props.phone}/></td>
          <td align="center"><ComplainLink url={this.props.uplink}/></td>
        </tr>
      </>
    );
  }
}

export default Agent;
export  {AgentBody};