import React from 'react';
import List from './List'

export default function Navigation() {
  return (
    <div id="navigation">
         <ul className="menubar">
           
            <List href="/" url="/images/rooms.png" width="" height="" text=""/>
            <List href="/services" url="/images/help.png" width="" height="" text="কাষ্টমার সার্ভিস"/>
            <List href="/admin" url="/images/configuration.png" width="" height="" text="এডমিন"/>
            <List href="/subadmin" url="" width="" height="" text="সাব এডমিন"/>
            <List href="/supersub" url="" width="" height="" text="সুপার সাব"/>
            <List href="/superadmin" url="" width="" height="" text="সুপার"/>
            <List href="/master" url="" width="" height="" text="মাস্টার"/>
            

         
       
       </ul>
    </div>
  )
}
