import React, { Component } from "react";
import SpanImage from "../comp/SpanImage";
import { Link } from "react-router-dom";
import Navigation from "../comp/Navigation";
import SearchForm from "../comp/SearchForm";
import Agent, { AgentBody } from "../comp/Agent";
import {VELKI_API_URL,VELKI_API_KEY} from '../config/Constant';

let admindata = [
  {
    id: "0",
    agent_type: "Sub Admin",
    agent_name: "Sub Admin",
    agent_phone: "000000000000",
    agent_upline: "1",
    agent_altphone: null,
    agent_email: "",
    agent_uodateon: "2022-09-17 01:10:29",
    agent_creted: "2022-09-17 01:10:29",
  },
];

//"http://localhost/agent/api/?agent_type=site_admin";
export default class Services extends Component {
  constructor() {
    super();
    this.state = {
      agents: admindata,
      isloading: false,
    };
  }
  async componentDidMount() {
    console.log("cdm");
    let url = `${VELKI_API_URL}/api/?agent_type=customer_service`;     
    let res = await fetch(url);
    let parsedData = await res.json();
    this.setState({
      agents: parsedData.data,
      isloading: false,
    });
    console.log("Parsed");
    console.log(parsedData.data);
  }

  render() {
    const { agents, isloading } = this.state;
    console.log("Render Agents:");
    console.log(agents);
    console.log(isloading);

    return (
      <div id="wrapper">
        <span>
          <Link to="/">{
            /* <SpanImage url="images/agent_list1.jpg" width="600" height="80" /> */
            <SpanImage url="https://www.static112233.com/assets/images/tiger786/logo-tiger786.webp" width="360" height="100" /> 
            }
            <h1>Tiger786.bet-এজেন্ট লিস্ট</h1></Link>
        </span>
        <Navigation />
        <SearchForm></SearchForm>
        <div style={{ minHeight: "50px" }}>
          <table className="data" style={{ width: "95%" }}>
            <thead>
              <Agent
                type="Type"
                name="Name"
                id="Id No"
                app="App Link"
                phone="Phone Number"
                uplink="Complain"
              />
            </thead>
            <tbody>
              {agents.map((item) => (
                <AgentBody
                  key={item.id}
                  keyid={item.id}
                  rowcls="odd"
                  type="Customer Service"//{item.agent_type}
                  name={item.agent_name}
                  id={item.id}
                  fb={item.agent_fb}
                  app={item.agent_phone}
                  phone={item.agent_phone}
                  uplink={item.agent_upline}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

